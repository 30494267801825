const options = [
  {
    option: "¿Cómo utilizar el micrófono?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Como_usar_el_microfono_en_Lisa.mp4",
  },
  {
    option: "¿Como restablecer contraseña?",
    videoLink: "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Como_restablecer_contrase%C3%B1a.mp4"
  },
  {
    option:
      "¿Cómo consultar mi deuda pendiente de seguro de automóvil con Seguros Crecer?",
    videoLink:
      "https://nyc3.digitaloceanspaces.com/prototipos/lisa-app-videos/Consulta_de_balance_pendiente_y_link_de_pago.mp4",
  },
  {
    option: "¿Cómo cotizar un seguro de automóvil en Seguros Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Cotizaci%C3%B3n_de_seguro_para_vehiculo.mp4",
  },
  {
    option: "¿Cómo consultar el balance de mi fondo de pensión en AFP Crecer?",
    videoLink:
      "https://nyc3.digitaloceanspaces.com/prototipos/lisa-app-videos/Consulta_de_balance_de_CCI.mp4",
  },
  {
    option: "¿Cómo vincularme como empleado de Seguros Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Vinculaci%C3%B3n_como_empleado_de_Seguros_Crecer.mp4",
  },{
      option: "¿Como consultar estatus de un siniestro como empleado?",
      videoLink: "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Consulta_de_estatus_de_un_siniestro_como_empleado.mp4"
  },
  {
    option: "¿Cómo vincularme como asegurado de Seguros Crecer?",
    videoLink:
      "https://nyc3.digitaloceanspaces.com/prototipos/lisa-app-videos/Vinculaci%C3%B3n_como_asegurado_en_Seguros_Crecer.mp4",
  },
  {
    option: "¿Cómo vincularme como afiliado de AFP Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Vinculaci%C3%B3n_como_afiliado_en_AFP.mp4",
  },
  {
    option: "¿Cómo saber a cuáles organizaciones estoy vinculado?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Como_vincularne_a_organizaciones.mp4",
  },
  {
    option: "¿Cómo vincularme como intermediario en Seguros Crecer?",
    videoLink: "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Vinculaci%C3%B3n_como_intermediario_en_Seguros_Crecer.mp4",
  },{
    option: "Como consultar balance pendiente de mis clientes como intermediario en Seguros Crecer",
    videoLink: "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Consulta_de_balance_pendiente_como_intermediario.mp4"
  },
  {
    option:
      "¿Cómo consultar estatus siniestro de mi clientes como intermediario en Seguros Crecer?",
    videoLink:
      "https://prototipos.nyc3.cdn.digitaloceanspaces.com/lisa-app-videos/Consulta_de_estatus_de_siniestro.mp4",
  },
];

export default options;
