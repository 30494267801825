import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
  },
  modalContent: {
    backgroundColor: "white",
    padding: "1rem",
    borderRadius: "1rem",
    boxShadow: "0px 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
    maxWidth: "90vw",
    width: "90%",
    maxHeight: "80vh",
    overflowY: "auto",
    textAlign: "center",
    transition: "none",
    fontSize: "14px",
    "@media (max-width: 600px)": {
      padding: "0.75rem",
      fontSize: "12px",
      maxWidth: "95vw",
    },
    "@media (max-width: 400px)": {
      padding: "0.5rem",
      fontSize: "11px",
      maxWidth: "100vw",
      borderRadius: "0.5rem",
    },
    "@media (min-width: 960px)": {
      width: "50%",
    },
    "@media (min-width: 1500px)": {
      width: "50%",
    },
    "@media (max-height: 660px)": {
      maxHeight: "60vh",
      maxWidth: "80vw",
    },
  },
  modalTitle: {
    margin: "1rem 0",
    fontSize: "1.25rem",
    "@media (max-width: 600px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 400px)": {
      fontSize: "0.875rem",
    },
  },
  modalList: {
    listStyle: "none",
    padding: 0,
    margin: "1rem 0",
    transition: "none",
    "@media (max-width: 400px)": {
      margin: "0.5rem 0",
    },
  },
  modalListItem: {
    marginBottom: "0.75rem",
    transition: "none",
    "@media (max-width: 600px)": {
      marginBottom: "0.5rem",
    },
    "@media (max-width: 400px)": {
      marginBottom: "0.3rem",
    },
  },
  modalClose: {
    fontSize: "17px",
    backgroundColor: "rgb(128 128 128)",
    color: "#ffffff",
    textAlign: "center",
    borderRadius: "0.75rem",
    display: "block",
    cursor: "pointer",
    padding: "0.15rem",
    width: "100%",
    border: "none",
    boxShadow: "none",
    marginTop: "1rem",
    "@media (max-width: 600px)": {
      fontSize: "14px",
      padding: "0.5rem",
    },
    "@media (max-width: 400px)": {
      fontSize: "12px",
      padding: "0.4rem",
      marginTop: "0.5rem",
    },
  },
  modalLink: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#8066EC",
    color: "white",
    padding: "0.75rem 1rem",
    borderRadius: "0.55rem",
    textDecoration: "none",
    transition: "unset",
    textAlign: "left",
    "&:hover": {
      backgroundColor: "#6749b9",
    },
    "@media (max-width: 600px)": {
      padding: "0.75rem",
    },
    "@media (max-width: 400px)": {
      padding: "0.5rem",
    },
  },
  linkIcon: {
    width: "24px",
    height: "24px",
    marginRight: "0.625rem",
    "@media (max-width: 600px)": {
      width: "20px",
      height: "20px",
      marginRight: "0.5rem",
    },
    "@media (max-width: 400px)": {
      width: "18px",
      height: "18px",
      marginRight: "0.4rem",
    },
  },
  linkText: {
    flex: 1,
  },
  videoBkModalContent: {
    backgroundColor: "currentColor",
    padding: "1rem",
    borderRadius: "1rem",
    boxShadow: "0px 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
    maxWidth: "50vw",
    width: "100%",
    maxHeight: "65vh",
    overflowY: "auto",
    textAlign: "center",
    transition: "none",
    fontSize: "13px",
    "@media (max-width: 600px)": {
      padding: "0.5rem",
      fontSize: "12px",
      maxWidth: "95vw",
      maxHeight: "85vh",
    },
    "@media (max-width: 400px)": {
      padding: "0.4rem",
      fontSize: "11px",
      maxWidth: "100vw",
      maxHeight: "90vh",
      borderRadius: "0.5rem",
    },
    "@media (max-height: 660px)": {
      maxHeight: "60vh",
      maxWidth: "80vw",
    },
  },
  videoContainer: {
    position: "relative",
    paddingBottom: "56.25%",
    height: "0",
    overflow: "hidden",
    maxWidth: "50%",
    background: "#000",
  },
}))

export default useStyles
