import React, { useState } from "react"
import useStyles from "./helpModalStyles"
import options from "../constants/optionsMenuHelp"
import { Modal, Box } from "@mui/material"
import { ReactComponent as LinkIcon } from "../media/icons/link.svg"

export default function HelpModal({ isVisible, onClose }) {
  const classes = useStyles()
  const [selectedVideo, setSelectedVideo] = useState(null)

  const handleVideoClick = (videoLink) => {
    setSelectedVideo(videoLink)
  }

  const handleCloseVideoModal = () => {
    setSelectedVideo(null)
  }

  return (
    <>
      <Modal
        open={isVisible}
        onClose={onClose}
        aria-labelledby="help-modal-title"
        aria-describedby="help-modal-description"
        className={classes.modal}
      >
        <Box className={classes.modalContent}>
          <h4 id="help-modal-title" className={classes.modalTitle}>
            Cuéntanos en qué te podemos ayudar:
          </h4>
          <ul className={classes.modalList}>
            {options.map((item, idx) => (
              <li key={idx} className={classes.modalListItem}>
                <a
                  onClick={() => handleVideoClick(item.videoLink)}
                  className={classes.modalLink}
                >
                  <LinkIcon className={classes.linkIcon} />
                  {item.option}
                </a>
              </li>
            ))}
          </ul>
          <button
            className={classes.modalClose}
            onClick={onClose}
            aria-label="Cerrar"
          >
            Cerrar
          </button>
        </Box>
      </Modal>

      <Modal
        open={Boolean(selectedVideo)}
        onClose={handleCloseVideoModal}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
        className={classes.modal}
      >
        <Box className={classes.videoBkModalContent}>
          {selectedVideo && (
            <video style={classes.videoStyle} controls autoPlay >
              <source src={selectedVideo} type="video/mp4" />
              Tu navegador no soporta el elemento de video.
            </video>
          )}
          <button
            className={classes.modalClose}
            onClick={handleCloseVideoModal}
          >
            Cerrar
          </button>
        </Box>
      </Modal>
    </>
  )
}
